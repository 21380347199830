import { cn } from '#app/utils/misc.js'

type LogoProps = {
  width?: number
  height?: number
  className?: string
  [key: string]: unknown | undefined
}

export function Logo({ width, height, className, ...args }: LogoProps) {
  return (
    <span className="flex items-center justify-center gap-2">
      <img
        src="/brand/logo-full.svg"
        alt="Immotr.ee"
        width={width}
        height={height}
        className={cn('h-[46px] w-[205px]', className)}
        {...args}
      />
    </span>
  )
}
